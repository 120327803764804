@font-face {
  font-family: "Scunthorpe";
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/scunthorpe-sans.bdadc8e6.otf); /* IE9 Compat Modes */
  src: local("Scunthorpe"), url(/static/media/scunthorpe-sans.9cf87afb.woff) format("woff"); /* Modern Browsers */
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "PT Serif", serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type="file"] {
  width: 100%;
}

body {
  font-family: Calibri !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-size: 1.6rem !important;
  line-height: 2.2rem !important;
  color: #666666;
  background-color: #f2f7f2 !important; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Cinzel !important;
  font-weight: 500; }

h1 {
  text-transform: uppercase;
  max-width: calc(100% - 70px);
  margin-bottom: 20px !important;
  font-weight: 600; }

h3 {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 600; }

a {
  color: #3f84e5;
  position: relative; }

label {
  font-size: 1.8rem;
  text-transform: uppercase; }

p {
  font-size: 1.8rem;
  line-height: 2rem; }
  p.introText {
    font-weight: bold;
    padding: 0 0 20px; }

.request__result {
  color: #666666; }

.form-group {
  margin-bottom: 2rem !important; }

.btn-primary {
  background-color: #3f84e5 !important;
  font-size: 1.8rem !important;
  border-color: #3f84e5 !important;
  padding: 10px 20px !important; }

.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  margin-bottom: 2rem; }

#root {
  background-image: url(/images/background.jpg);
  background-size: cover; }

.App {
  margin-bottom: 20px; }
  .App .absolution-header {
    position: relative;
    z-index: 0;
    display: flex; }
    .App .absolution-header::before {
      content: "";
      position: absolute;
      top: 0;
      left: 15px;
      width: calc(100% - 30px);
      height: 100%;
      opacity: 0.4;
      z-index: -1;
      background-color: #f2f7f2; }
    .App .absolution-header__logo {
      width: 100%;
      text-align: left;
      flex-grow: 1;
      flex-basis: 0; }
      .App .absolution-header__logo img {
        max-width: 100%;
        height: auto;
        max-height: 60px; }
        @media screen and (min-width: 796px) {
          .App .absolution-header__logo img {
            max-height: 90px; } }
      .App .absolution-header__logo ::after {
        display: none; }
    .App .absolution-header .navbar {
      flex-grow: 1;
      flex-basis: 0;
      align-items: flex-end; }
      @media screen and (min-width: 796px) {
        .App .absolution-header .navbar {
          align-items: flex-start; } }
      @media screen and (min-width: 796px) {
        .App .absolution-header .navbar-collapse.collapse {
          display: initial !important; } }
      .App .absolution-header .navbar-nav {
        flex-direction: column !important;
        float: right; }
        @media screen and (min-width: 796px) {
          .App .absolution-header .navbar-nav {
            flex-direction: row !important; } }
      .App .absolution-header .navbar-nav .nav-item {
        cursor: pointer;
        text-transform: uppercase;
        color: #333333;
        line-height: 18px;
        font-size: 14px;
        font-weight: bold;
        margin-left: 15px; }
      .App .absolution-header .navbar-toggler {
        position: absolute;
        right: 10px; }
      .App .absolution-header .navbar-collapse {
        position: absolute;
        right: 10px; }

.absolution-menu__grid {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 1fr 1fr 1fr 1fr; }

.absolution-menu__title {
  display: none;
  font-size: 1.6rem;
  line-height: 1.8rem;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%; }
  @media screen and (min-width: 796px) {
    .absolution-menu__title {
      display: block; } }
  .absolution-menu__title :hover {
    box-shadow: none; }

.absolution-menu a {
  color: #f2f7f2;
  font-weight: bold;
  text-align: center;
  padding: 15px 0px 2px;
  display: flex;
  flex-direction: column; }
  .absolution-menu a .active {
    background-color: #ffffff; }
  .absolution-menu a svg {
    color: #f2f7f2;
    width: 100% !important;
    padding: 15px 8px;
    min-height: 8rem;
    transition: ease-out 0.3s; }
    @media screen and (min-width: 796px) {
      .absolution-menu a svg {
        min-height: 11rem; } }
  .absolution-menu a .arrow-down {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid transparent;
    margin: 0px auto; }
  @media screen and (min-width: 796px) {
    .absolution-menu a:hover svg {
      padding: 20px 8px; } }
  .absolution-menu a::after {
    display: none; }

.absolution-menu__link-item {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .absolution-menu__link-item.nav-new {
    background-color: #3f84e5; }
  .absolution-menu__link-item.nav-gift {
    background-color: #b20d30; }
  .absolution-menu__link-item.nav-view {
    background-color: #3f784c; }
  .absolution-menu__link-item.nav-guarantee {
    background-color: #ed7d31; }

.absolution-menu .nav-new.active .arrow-down {
  border-top-color: #3f84e5; }

.absolution-menu .nav-gift.active .arrow-down {
  border-top-color: #b20d30; }

.absolution-menu .nav-view.active .arrow-down {
  border-top-color: #3f784c; }

.absolution-menu .nav-guarantee.active .arrow-down {
  border-top-color: #ed7d31; }

section {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
  section .media {
    min-width: 0;
    margin: 5px; }
  section img {
    width: 450px;
    max-width: 100%;
    max-height: 450px; }

.footer {
  color: #3f84e5;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center !important;
  z-index: 1;
  position: relative;
  border: none; }
  .footer .navbar-nav {
    width: auto;
    flex-direction: row; }
    .footer .navbar-nav div {
      padding: 5px;
      height: 20px;
      margin: 10px;
      line-height: 16px;
      font-weight: bold;
      font-size: 14px;
      text-align: center;
      cursor: pointer;
      text-transform: uppercase; }
  .footer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    z-index: -1;
    background-color: #f2f7f2; }

.contentWrapper .fade-enter {
  opacity: 1;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.contentWrapper .fade-enter-active {
  opacity: 1;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  transition: opacity 0ms, -webkit-transform 500ms;
  transition: opacity 0ms, transform 500ms;
  transition: opacity 0ms, transform 500ms, -webkit-transform 500ms; }

.contentWrapper .fade-exit {
  opacity: 1; }

.contentWrapper .fade-exit-active {
  opacity: 0.01;
  transition: opacity 100ms ease-out; }

.content {
  min-height: calc(100vh - 284px);
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 0; }
  @media screen and (min-width: 796px) {
    .content {
      min-height: calc(100vh - 372px); } }
  .content::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    z-index: -1;
    background-color: #f2f7f2; }

.LoaderButton .spinner-border {
  margin-right: 10px;
  margin-bottom: 2px; }

.cardlist_downloadPdf {
  text-align: left;
  margin: auto 20px 20px 0; }
  .cardlist_downloadPdf button {
    font-size: 16px;
    padding-left: 0; }

.cardlist_guaranteeModal {
  width: 80vw;
  height: calc(80vw * 1.4);
  max-width: 776px;
  max-height: calc(776px * 1.4); }

.card {
  border: 1px solid #3f784c;
  font-family: "Scunthorpe";
  text-rendering: optimizeLegibility;
  -webkit-font-variant-ligatures: discretionary-ligatures;
  -webkit-font-feature-settings: "dlig";
          font-feature-settings: "dlig";
  font-variant-ligatures: discretionary-ligatures;
  box-shadow: 3px 2px 5px #495057;
  margin-bottom: 2rem; }
  .card-body {
    padding: 0;
    display: flex;
    flex-flow: column nowrap;
    flex-direction: column; }
  .card-title {
    padding: 7px 17px;
    color: #666666;
    font-weight: bold;
    text-align: right; }
  .card-text {
    padding: 7px 17px;
    display: flex;
    flex-direction: column;
    flex-grow: 2; }
  .card-sinType {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin-bottom: 1.2rem;
    color: #333333; }
  .card-sinDesc {
    margin-bottom: 1.2rem; }

.Home .lander {
  display: flex;
  flex-direction: column; }
  @media screen and (min-width: 796px) {
    .Home .lander {
      flex-direction: row; } }
  .Home .lander h1 {
    color: #3f84e5; }
  .Home .lander p > a {
    text-align: center; }
  .Home .lander > div {
    width: 100%; }
  .Home .lander .featured-sin {
    display: flex;
    flex-direction: row; }
  .Home .lander .card {
    width: 100%;
    border: 1px solid #ed7d31; }

.Home .sins h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis; }

.Home .sins p {
  color: #666; }

.Home .list-group {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr;
  grid-column-gap: 2rem;
  -webkit-column-gap: 2rem;
          column-gap: 2rem; }
  @media screen and (min-width: 796px) {
    .Home .list-group {
      grid-template-columns: 1fr 1fr; } }

.Home__loading {
  min-height: 50vh; }

.Home .card {
  border: 1px solid #3f84e5; }

.voucher-check {
  position: relative; }
  .voucher-check__spinner {
    position: absolute;
    right: 10px;
    top: 30px; }
  .voucher-check .svg-inline--fa {
    position: absolute;
    right: 10px;
    top: 33px;
    font-size: 16px; }
  .voucher-check .fa-check {
    color: #b20d30; }
  .voucher-check .fa-times {
    color: red; }

body {
  font-family: Calibri !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-size: 1.6rem !important;
  line-height: 2.2rem !important;
  color: #666666;
  background-color: #f2f7f2 !important; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Cinzel !important;
  font-weight: 500; }

h1 {
  text-transform: uppercase;
  max-width: calc(100% - 70px);
  margin-bottom: 20px !important;
  font-weight: 600; }

h3 {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 600; }

a {
  color: #3f84e5;
  position: relative; }

label {
  font-size: 1.8rem;
  text-transform: uppercase; }

p {
  font-size: 1.8rem;
  line-height: 2rem; }
  p.introText {
    font-weight: bold;
    padding: 0 0 20px; }

.request__result {
  color: #666666; }

.form-group {
  margin-bottom: 2rem !important; }

.btn-primary {
  background-color: #3f84e5 !important;
  font-size: 1.8rem !important;
  border-color: #3f84e5 !important;
  padding: 10px 20px !important; }

.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  margin-bottom: 2rem; }

.sinFilter {
  font-size: 1.8rem;
  line-height: 2.3rem;
  color: #3f84e5;
  font-family: "Scunthorpe";
  text-rendering: optimizeLegibility;
  -webkit-font-variant-ligatures: discretionary-ligatures;
  -webkit-font-feature-settings: "dlig";
          font-feature-settings: "dlig";
  font-variant-ligatures: discretionary-ligatures;
  border-color: #3f84e5;
  box-shadow: 3px 2px 5px #495057; }
  .sinFilter .selectGroupStyle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #3f84e5;
    font-weight: bold;
    font-family: "Scunthorpe"; }
  .sinFilter .selectGroupStyle-badge {
    background-color: #3f84e5;
    border-radius: 2em;
    color: #f2f7f2;
    display: inline-block;
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
    min-width: 1px;
    padding: 0.16666666666667em 0.5em;
    text-align: center; }
  .sinFilter__indicator-separator {
    background-color: #3f84e5 !important; }
  .sinFilter__indicator {
    color: #3f84e5 !important; }

body {
  font-family: Calibri !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-size: 1.6rem !important;
  line-height: 2.2rem !important;
  color: #666666;
  background-color: #f2f7f2 !important; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Cinzel !important;
  font-weight: 500; }

h1 {
  text-transform: uppercase;
  max-width: calc(100% - 70px);
  margin-bottom: 20px !important;
  font-weight: 600; }

h3 {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 600; }

a {
  color: #3f84e5;
  position: relative; }

label {
  font-size: 1.8rem;
  text-transform: uppercase; }

p {
  font-size: 1.8rem;
  line-height: 2rem; }
  p.introText {
    font-weight: bold;
    padding: 0 0 20px; }

.request__result {
  color: #666666; }

.form-group {
  margin-bottom: 2rem !important; }

.btn-primary {
  background-color: #3f84e5 !important;
  font-size: 1.8rem !important;
  border-color: #3f84e5 !important;
  padding: 10px 20px !important; }

.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  margin-bottom: 2rem; }

.NewSin {
  color: #3f84e5;
  position: relative; }
  .NewSin form {
    padding-bottom: 15px; }
  .NewSin__packagesLoading {
    min-height: 20vh; }
  .NewSin textarea,
  .NewSin input,
  .NewSin select, .NewSin_create-select {
    font-size: 1.8rem;
    line-height: 2.3rem;
    color: #3f84e5;
    margin-bottom: 1rem;
    border-radius: 4px;
    border-color: #3f84e5;
    box-shadow: 3px 2px 5px #495057;
    font-family: "Scunthorpe";
    text-rendering: optimizeLegibility;
    -webkit-font-variant-ligatures: discretionary-ligatures;
    -webkit-font-feature-settings: "dlig";
            font-feature-settings: "dlig";
    font-variant-ligatures: discretionary-ligatures; }
  .NewSin textarea {
    height: 110px; }
  .NewSin button.LoaderButton {
    background-color: #3f84e5 !important;
    border-color: #3f84e5 !important;
    color: #f2f7f2;
    text-transform: uppercase;
    font-weight: bold; }
  .NewSin .NewSin__control {
    border-color: #3f84e5 !important; }
  .NewSin__packageList {
    border-radius: 4px;
    border: 1px solid #3f84e5;
    box-shadow: 3px 2px 5px #495057;
    padding: 5px 1px; }
  .NewSin__btnBack {
    font-size: 1.75rem !important;
    text-transform: uppercase;
    margin-bottom: 20px;
    position: absolute;
    right: 0;
    top: 0px; }
  .NewSin .absolution-packages {
    display: flex !important;
    padding: 0 !important;
    margin: 1px 0 !important;
    border: none !important; }
    .NewSin .absolution-packages div {
      align-items: flex-start;
      margin: 0 5px;
      padding: 5px 10px;
      font-size: 1.4rem; }
      .NewSin .absolution-packages div:first-child {
        margin-left: 0; }
    .NewSin .absolution-packages .title {
      width: 25%;
      background-color: #4db1c9;
      color: #3f84e5;
      font-weight: bold; }
    .NewSin .absolution-packages .price {
      width: 10%;
      background-color: #4db1c9;
      color: #3f84e5;
      text-align: right; }
    .NewSin .absolution-packages .guarantee {
      width: 10%;
      color: #b20d30;
      font-size: 1.2rem;
      line-height: 1.4rem;
      text-align: center; }
    .NewSin .absolution-packages .description {
      width: 55%;
      color: #3f84e5;
      font-weight: bold; }
    .NewSin .absolution-packages.selected .title {
      background-color: #3f84e5;
      color: #f2f7f2; }
    .NewSin .absolution-packages.selected .price {
      background-color: #3f84e5;
      color: #f2f7f2; }
  .NewSin .StripeElement {
    border-color: #3f84e5;
    box-shadow: 3px 2px 5px #495057; }

.sins form {
  padding-bottom: 15px; }

.sins form textarea {
  height: 300px;
  font-size: 24px; }

.sins .btn-link {
  text-transform: uppercase; }

@media all and (min-width: 480px) {
  .Login form {
    margin: 0 auto;
    max-width: 320px; }
  .Login .FacebookButton {
    max-width: 320px;
    margin: 0 auto; } }

.Login form {
  margin-top: 40px; }

.Login h1 {
  color: #3f84e5; }

.Login .FacebookButton {
  margin-top: 20px; }

.Login__forgot-pass {
  text-align: right; }

@media all and (min-width: 480px) { 
    .Signup {
        padding: 60px 0;
    }
    .Signup form {
        margin: 0 auto; 
        max-width: 320px;
    }
}
.Signup form span.help-block {
    font-size: 14px;
    padding-bottom: 10px;
    color: #999;
}
.NotFound {
    padding-top: 100px;
    text-align: center;
}
@media all and (min-width: 480px) {
    .ResetPassword {
        padding: 60px 0;
    }
    .ResetPassword form {
        margin: 0 auto;
        max-width: 320px;
    }
    .ResetPassword .success {
        max-width: 400px;
    }
    }
    .ResetPassword .success {
        margin: 0 auto;
        text-align: center;
    }
    .ResetPassword .success .glyphicon {
        color: grey;
        font-size: 30px;
        margin-bottom: 30px;
}
.confessions h1 {
  color: #3f84e5; }

.confessions__list {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr;
  grid-column-gap: 2rem;
  -webkit-column-gap: 2rem;
          column-gap: 2rem;
  margin-bottom: 15px; }
  @media screen and (min-width: 796px) {
    .confessions__list {
      grid-template-columns: 1fr 1fr; } }

.confessions__loading {
  min-height: 50vh; }

body {
  font-family: Calibri !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-size: 1.6rem !important;
  line-height: 2.2rem !important;
  color: #666666;
  background-color: #f2f7f2 !important; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Cinzel !important;
  font-weight: 500; }

h1 {
  text-transform: uppercase;
  max-width: calc(100% - 70px);
  margin-bottom: 20px !important;
  font-weight: 600; }

h3 {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 600; }

a {
  color: #3f84e5;
  position: relative; }

label {
  font-size: 1.8rem;
  text-transform: uppercase; }

p {
  font-size: 1.8rem;
  line-height: 2rem; }
  p.introText {
    font-weight: bold;
    padding: 0 0 20px; }

.request__result {
  color: #666666; }

.form-group {
  margin-bottom: 2rem !important; }

.btn-primary {
  background-color: #3f84e5 !important;
  font-size: 1.8rem !important;
  border-color: #3f84e5 !important;
  padding: 10px 20px !important; }

.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  margin-bottom: 2rem; }

.BuyVoucher {
  position: relative; }
  .BuyVoucher form {
    padding-bottom: 15px; }
  .BuyVoucher__packagesLoading {
    min-height: 15vh; }
  .BuyVoucher textarea,
  .BuyVoucher input,
  .BuyVoucher select {
    font-size: 1.8rem;
    line-height: 2.3rem;
    color: #b20d30;
    margin-bottom: 1rem;
    border-radius: 4px;
    border-color: #b20d30;
    box-shadow: 3px 2px 5px #495057;
    font-family: "Scunthorpe";
    text-rendering: optimizeLegibility;
    -webkit-font-variant-ligatures: discretionary-ligatures;
    -webkit-font-feature-settings: "dlig";
            font-feature-settings: "dlig";
    font-variant-ligatures: discretionary-ligatures; }
  .BuyVoucher textarea {
    height: 120px; }
  .BuyVoucher__packageList {
    border-radius: 4px;
    border: 1px solid #b20d30;
    box-shadow: 3px 2px 5px #495057;
    padding: 5px 1px; }
  .BuyVoucher button.LoaderButton {
    background-color: #b20d30 !important;
    border-color: #b20d30 !important;
    color: #f2f7f2;
    text-transform: uppercase;
    font-weight: bold; }
  .BuyVoucher__btnBack {
    font-size: 1.75rem !important;
    text-transform: uppercase;
    margin-bottom: 20px;
    position: absolute;
    right: 0;
    top: 0px; }
  .BuyVoucher .absolution-packages {
    display: flex !important;
    padding: 0 !important;
    margin: 1px 0 !important;
    border: none !important; }
    .BuyVoucher .absolution-packages div {
      align-items: flex-start;
      margin: 0 5px;
      padding: 5px 10px;
      font-size: 1.4rem; }
      .BuyVoucher .absolution-packages div:first-child {
        margin-left: 0; }
    .BuyVoucher .absolution-packages .title {
      width: 25%;
      background-color: #a6e37d;
      color: #b20d30;
      font-weight: bold; }
    .BuyVoucher .absolution-packages .price {
      width: 10%;
      background-color: #a6e37d;
      color: #b20d30;
      text-align: right; }
    .BuyVoucher .absolution-packages .guarantee {
      width: 10%;
      color: #b20d30;
      font-size: 1.2rem;
      line-height: 1.4rem;
      text-align: center; }
    .BuyVoucher .absolution-packages .description {
      width: 55%;
      color: #b20d30;
      font-weight: bold; }
    .BuyVoucher .absolution-packages.selected .title {
      background-color: #b20d30;
      color: #f2f7f2; }
    .BuyVoucher .absolution-packages.selected .price {
      background-color: #b20d30;
      color: #f2f7f2; }
  .BuyVoucher .StripeElement {
    border-color: #b20d30;
    box-shadow: 3px 2px 5px #495057; }

.Guarantee {
  color: #ed7d31; }
  .Guarantee form {
    padding-bottom: 15px; }
  .Guarantee__certificateWrapper {
    text-align: center; }
  .Guarantee__certificate {
    width: 100vw;
    height: calc(100vw * 1.4);
    max-width: 776px;
    max-height: calc(776px * 1.4); }

.Faqs h1 {
  color: #3f84e5; }

.Faqs .card {
  border: 1px solid #3f84e5;
  font-family: "Scunthorpe";
  font-size: 15px;
  line-height: 20px;
  text-rendering: optimizeLegibility;
  -webkit-font-variant-ligatures: discretionary-ligatures;
  -webkit-font-feature-settings: "dlig";
          font-feature-settings: "dlig";
  font-variant-ligatures: discretionary-ligatures;
  box-shadow: 3px 2px 5px #495057; }
  .Faqs .card-body {
    padding: 0; }
  .Faqs .card-header {
    padding: 7px 17px;
    color: #3f84e5;
    font-weight: bold;
    cursor: pointer; }
  .Faqs .card-body {
    padding: 7px 17px 15px; }

body {
  font-family: Calibri !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-size: 1.6rem !important;
  line-height: 2.2rem !important;
  color: #666666;
  background-color: #f2f7f2 !important; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Cinzel !important;
  font-weight: 500; }

h1 {
  text-transform: uppercase;
  max-width: calc(100% - 70px);
  margin-bottom: 20px !important;
  font-weight: 600; }

h3 {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 600; }

a {
  color: #3f84e5;
  position: relative; }

label {
  font-size: 1.8rem;
  text-transform: uppercase; }

p {
  font-size: 1.8rem;
  line-height: 2rem; }
  p.introText {
    font-weight: bold;
    padding: 0 0 20px; }

.request__result {
  color: #666666; }

.form-group {
  margin-bottom: 2rem !important; }

.btn-primary {
  background-color: #3f84e5 !important;
  font-size: 1.8rem !important;
  border-color: #3f84e5 !important;
  padding: 10px 20px !important; }

.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  margin-bottom: 2rem; }

.Contactus {
  color: #3f84e5;
  position: relative; }
  .Contactus form {
    padding-bottom: 15px; }
  .Contactus__packagesLoading {
    min-height: 20vh; }
  .Contactus textarea,
  .Contactus input,
  .Contactus select, .Contactus_create-select {
    font-size: 1.8rem;
    line-height: 2.3rem;
    color: #3f84e5;
    margin-bottom: 1rem;
    border-radius: 4px;
    border-color: #3f84e5;
    box-shadow: 3px 2px 5px #495057;
    font-family: "Scunthorpe";
    text-rendering: optimizeLegibility;
    -webkit-font-variant-ligatures: discretionary-ligatures;
    -webkit-font-feature-settings: "dlig";
            font-feature-settings: "dlig";
    font-variant-ligatures: discretionary-ligatures; }
  .Contactus textarea {
    height: 110px; }
  .Contactus button.LoaderButton {
    background-color: #3f84e5 !important;
    border-color: #3f84e5 !important;
    color: #f2f7f2;
    text-transform: uppercase;
    font-weight: bold; }
  .Contactus .Contactus__control {
    border-color: #3f84e5 !important; }
  .Contactus__packageList {
    border-radius: 4px;
    border: 1px solid #3f84e5;
    box-shadow: 3px 2px 5px #495057;
    padding: 5px 1px; }
  .Contactus__create-select {
    font-size: 1.8rem;
    line-height: 2.3rem;
    color: #3f84e5;
    font-family: "Scunthorpe";
    text-rendering: optimizeLegibility;
    -webkit-font-variant-ligatures: discretionary-ligatures;
    -webkit-font-feature-settings: "dlig";
            font-feature-settings: "dlig";
    font-variant-ligatures: discretionary-ligatures;
    border-color: #3f84e5;
    box-shadow: 3px 2px 5px #495057; }
  .Contactus__indicator-separator {
    background-color: #3f84e5 !important; }
  .Contactus__indicator {
    color: #3f84e5 !important; }
  .Contactus__btnBack {
    font-size: 1.75rem !important;
    text-transform: uppercase;
    margin-bottom: 20px;
    position: absolute;
    right: 0;
    top: 0px; }
  .Contactus .absolution-packages {
    display: flex !important;
    padding: 0 !important;
    margin: 1px 0 !important;
    border: none !important; }
    .Contactus .absolution-packages div {
      align-items: flex-start;
      margin: 0 5px;
      padding: 5px 10px;
      font-size: 1.4rem; }
      .Contactus .absolution-packages div:first-child {
        margin-left: 0; }
    .Contactus .absolution-packages .title {
      width: 25%;
      background-color: #4db1c9;
      color: #3f84e5;
      font-weight: bold; }
    .Contactus .absolution-packages .price {
      width: 10%;
      background-color: #4db1c9;
      color: #3f84e5;
      text-align: right; }
    .Contactus .absolution-packages .guarantee {
      width: 10%;
      color: #b20d30;
      font-size: 1.2rem;
      line-height: 1.4rem;
      text-align: center; }
    .Contactus .absolution-packages .description {
      width: 55%;
      color: #3f84e5;
      font-weight: bold; }
    .Contactus .absolution-packages.selected .title {
      background-color: #3f84e5;
      color: #f2f7f2; }
    .Contactus .absolution-packages.selected .price {
      background-color: #3f84e5;
      color: #f2f7f2; }
  .Contactus .StripeElement {
    border-color: #3f84e5;
    box-shadow: 3px 2px 5px #495057; }

