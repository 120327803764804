@import "./variables.scss";
@import "./responsive";

.confessions {
  h1 {
    color: $sin-new;
  }
  &__list {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr;
    column-gap: 2rem;
    margin-bottom: 15px;
    @media screen and (min-width: $screen-medium) {
      grid-template-columns: 1fr 1fr;
    }
  }
  &__loading {
    min-height: 50vh;
  }
}
