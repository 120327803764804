@import "../styles/variables.scss";
@import "../styles/typography.scss";

.Contactus {
  color: $sin-new;
  position: relative;
  & form {
    padding-bottom: 15px;
  }

  &__packagesLoading {
    min-height: 20vh;
  }
  & textarea,
  input,
  select,
  &_create-select {
    font-size: 1.8rem;
    line-height: 2.3rem;
    color: $sin-new;
    margin-bottom: 1rem;
    border-radius: 4px;
    border-color: $sin-new;
    box-shadow: 3px 2px 5px $absolution-alternate-black;
    font-family: "Scunthorpe";
    text-rendering: optimizeLegibility;
    -webkit-font-variant-ligatures: discretionary-ligatures;
    font-variant-ligatures: discretionary-ligatures;
  }
  & textarea {
    height: 110px;
  }
  & button.LoaderButton {
    background-color: $sin-new !important;
    border-color: $sin-new !important;
    color: $absolution-white;
    text-transform: uppercase;
    font-weight: bold;
  }

  & &__control {
    // background-color: $sin-new-alternate;
    border-color: $sin-new !important;
  }

  &__packageList {
    border-radius: 4px;
    border: 1px solid $sin-new;
    box-shadow: 3px 2px 5px $absolution-alternate-black;
    padding: 5px 1px;
  }

  // Sin selector
  &__create-select {
    font-size: 1.8rem;
    line-height: 2.3rem;
    color: $sin-new;
    font-family: "Scunthorpe";
    text-rendering: optimizeLegibility;
    -webkit-font-variant-ligatures: discretionary-ligatures;
    font-variant-ligatures: discretionary-ligatures;
    border-color: $sin-new;
    box-shadow: 3px 2px 5px $absolution-alternate-black;
  }
  &__indicator-separator {
    background-color: $sin-new !important;
  }
  &__indicator {
    color: $sin-new !important;
  }

  &__btnBack {
    font-size: 1.75rem !important;
    text-transform: uppercase;
    margin-bottom: 20px;
    position: absolute;
    right: 0;
    top: 0px;
  }

  .absolution-packages {
    display: flex !important;
    padding: 0 !important;
    margin: 1px 0 !important;
    border: none !important;
    & div {
      align-items: flex-start;
      margin: 0 5px;
      padding: 5px 10px;
      font-size: 1.4rem;
      &:first-child {
        margin-left: 0;
      }
    }
    & .title {
      width: 25%;
      background-color: $sin-new-alternate;
      color: $sin-new;
      font-weight: bold;
    }
    & .price {
      width: 10%;
      background-color: $sin-new-alternate;
      color: $sin-new;
      text-align: right;
    }
    & .guarantee {
      width: 10%;
      color: $sin-gift;
      font-size: 1.2rem;
      line-height: 1.4rem;
      text-align: center;
    }
    & .description {
      width: 55%;
      color: $sin-new;
      font-weight: bold;
    }
    &.selected {
      & .title {
        background-color: $sin-new;
        color: $absolution-white;
      }
      & .price {
        background-color: $sin-new;
        color: $absolution-white;
      }
    }
  }
  .StripeElement {
    border-color: $sin-new;
    box-shadow: 3px 2px 5px $absolution-alternate-black;
  }
}
