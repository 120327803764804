@import "../styles/variables.scss";

.Login {
  @media all and (min-width: 480px) {
    form {
      margin: 0 auto;
      max-width: 320px;
    }
    .FacebookButton {
      max-width: 320px;
      margin: 0 auto;
    }
  }
  form {
    margin-top: 40px;
  }
  h1 {
    color: $sin-new;
  }
  .FacebookButton {
    margin-top: 20px;
  }

  &__forgot-pass {
    text-align: right;
  }
}
