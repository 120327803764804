@import "../styles/variables.scss";

.voucher-check {
  position: relative;
  &__spinner {
    position: absolute;
    right: 10px;
    top: 30px;
  }
  & .svg-inline--fa {
    position: absolute;
    right: 10px;
    top: 33px;
    font-size: 16px;
  }
  & .fa-check {
    color: $sin-gift;
  }
  & .fa-times {
    color: red;
  }
}
