@import "./variables.scss";
@import "./responsive";

.Home {
  .lander {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: $screen-medium) {
      flex-direction: row;
    }
    h1 {
      color: $sin-new;
    }
    p > a {
      text-align: center;
    }
    & > div {
      width: 100%;
    }
    & .featured-sin {
      display: flex;
      flex-direction: row;
    }
    .card {
      width: 100%;
      border: 1px solid $sin-guarantee;
    }
  }

  .sins h4 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    overflow: hidden;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .sins p {
    color: #666;
  }

  .list-group {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr;
    column-gap: 2rem;
    @media screen and (min-width: $screen-medium) {
      grid-template-columns: 1fr 1fr;
    }
  }
  &__loading {
    min-height: 50vh;
  }

  & .card {
    border: 1px solid $sin-new;
  }
}
