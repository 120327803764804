@import "./variables.scss";

.Guarantee {
  color: $sin-guarantee;
  & form {
    padding-bottom: 15px;
  }
  &__certificateWrapper {
    text-align: center;
  }
  &__certificate {
    width: 100vw;
    height: calc(100vw * 1.4);
    max-width: 776px;
    max-height: calc(776px * 1.4);
  }
}
