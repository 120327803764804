@import "./variables.scss";
@import "./responsive";

.cardlist {
  &_downloadPdf {
    text-align: left;
    margin: auto 20px 20px 0;

    button {
      font-size: 16px;
      padding-left: 0;
    }
  }
  &_guaranteeModal {
    width: 80vw;
    height: calc(80vw * 1.4);
    max-width: 776px;
    max-height: calc(776px * 1.4);
  }
}

.card {
  border: 1px solid $sin-view;
  font-family: "Scunthorpe";
  text-rendering: optimizeLegibility;
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;
  box-shadow: 3px 2px 5px $absolution-alternate-black;
  margin-bottom: 2rem;
  &-body {
    padding: 0;
    display: flex;
    flex-flow: column nowrap;
    flex-direction: column;
  }
  &-title {
    padding: 7px 17px;
    color: $absolution-black;
    font-weight: bold;
    text-align: right;
  }
  &-text {
    padding: 7px 17px;
    display: flex;
    flex-direction: column;
    flex-grow: 2;
  }
  &-sinType {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin-bottom: 1.2rem;
    color: $absolution-dark-black;
  }
  &-sinDesc {
    margin-bottom: 1.2rem;
  }
}
