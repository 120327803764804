.sins {
  & form {
    padding-bottom: 15px;
  }
  & form textarea {
    height: 300px;
    font-size: 24px;
  }

  & .btn-link {
    text-transform: uppercase;
  }
}
