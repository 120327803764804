@import "./variables.scss";
@import "./responsive";

.Faqs {
  h1 {
    color: $sin-new;
  }

  & .card {
    border: 1px solid $sin-new;
    font-family: "Scunthorpe";
    font-size: 15px;
    line-height: 20px;
    text-rendering: optimizeLegibility;
    -webkit-font-variant-ligatures: discretionary-ligatures;
    font-variant-ligatures: discretionary-ligatures;
    box-shadow: 3px 2px 5px $absolution-alternate-black;
    &-body {
      padding: 0;
    }
    &-header {
      padding: 7px 17px;
      color: $sin-new;
      font-weight: bold;
      cursor: pointer;
    }
    &-body {
      padding: 7px 17px 15px;
    }
  }
}
