@import "../styles/variables.scss";
@import "../styles/typography.scss";

.sinFilter {
  font-size: 1.8rem;
  line-height: 2.3rem;
  color: $sin-new;
  font-family: "Scunthorpe";
  text-rendering: optimizeLegibility;
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;
  border-color: $sin-new;
  box-shadow: 3px 2px 5px $absolution-alternate-black;

  .selectGroupStyle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $sin-new;
    font-weight: bold;
    font-family: "Scunthorpe";
  }
  .selectGroupStyle-badge {
    background-color: $sin-new;
    border-radius: 2em;
    color: $absolution-white;
    display: inline-block;
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
    min-width: 1px;
    padding: 0.16666666666667em 0.5em;
    text-align: center;
  }
  &__indicator-separator {
    background-color: $sin-new !important;
  }
  &__indicator {
    color: $sin-new !important;
  }
}
