@import "./responsive";
@import "./variables.scss";

#root {
  background-image: url(/images/background.jpg);
  background-size: cover;
}

.App {
  margin-bottom: 20px;

  .absolution-header {
    position: relative;
    z-index: 0;
    display: flex;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 15px;
      width: calc(100% - 30px);
      height: 100%;
      opacity: 0.4;
      z-index: -1;
      background-color: $absolution-white;
    }

    &__logo {
      width: 100%;
      text-align: left;
      flex-grow: 1;
      flex-basis: 0;
      img {
        max-width: 100%;
        height: auto;
        max-height: 60px;
        @media screen and (min-width: $screen-medium) {
          max-height: 90px;
        }
      }
      ::after {
        display: none;
      }
    }

    .navbar {
      flex-grow: 1;
      flex-basis: 0;
      align-items: flex-end;
      @media screen and (min-width: $screen-medium) {
        align-items: flex-start;
      }

      &-collapse.collapse {
        @media screen and (min-width: $screen-medium) {
          display: initial !important;
        }
      }
      &-nav {
        flex-direction: column !important;
        float: right;
        @media screen and (min-width: $screen-medium) {
          flex-direction: row !important;
        }
      }
      &-nav .nav-item {
        cursor: pointer;
        text-transform: uppercase;
        color: $absolution-dark-black;
        line-height: 18px;
        font-size: 14px;
        font-weight: bold;
        margin-left: 15px;
      }
      &-toggler {
        position: absolute;
        right: 10px;
      }
      &-collapse {
        position: absolute;
        right: 10px;
      }
    }
  }
}

.absolution-menu {
  &__grid {
    display: -ms-grid;
    display: grid;
    grid-gap: 5px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  &__title {
    display: none;
    font-size: 1.6rem;
    line-height: 1.8rem;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;

    @media screen and (min-width: $screen-medium) {
      display: block;
    }
    :hover {
      box-shadow: none;
    }
  }
  a {
    color: $absolution-white;
    font-weight: bold;
    text-align: center;
    padding: 15px 0px 2px;
    display: flex;
    flex-direction: column;
    .active {
      background-color: #ffffff;
    }
    svg {
      color: $absolution-white;
      width: 100% !important;
      padding: 15px 8px;
      min-height: 8rem;
      transition: ease-out 0.3s;
      @media screen and (min-width: $screen-medium) {
        min-height: 11rem;
      }
    }
    .arrow-down {
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid transparent;
      margin: 0px auto;
    }
    &:hover {
      svg {
        @media screen and (min-width: $screen-medium) {
          padding: 20px 8px;
        }
      }
    }

    &::after {
      display: none;
    }
  }
  &__link-item {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.nav-new {
      background-color: $sin-new;
    }
    &.nav-gift {
      background-color: $sin-gift;
    }
    &.nav-view {
      background-color: $sin-view;
    }
    &.nav-guarantee {
      background-color: $sin-guarantee;
    }
  }
  .nav-new {
    &.active .arrow-down {
      border-top-color: $sin-new;
    }
  }
  .nav-gift {
    &.active .arrow-down {
      border-top-color: $sin-gift;
    }
  }
  .nav-view {
    &.active .arrow-down {
      border-top-color: $sin-view;
    }
  }
  .nav-guarantee {
    &.active .arrow-down {
      border-top-color: $sin-guarantee;
    }
  }
}

// Site Logo
section {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .media {
    min-width: 0;
    margin: 5px;
  }
  img {
    width: 450px;
    max-width: 100%;
    max-height: 450px;
  }
}

.footer {
  color: $sin-new;
  width: 100%;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center !important;
  z-index: 1;
  position: relative;
  border: none;

  .navbar-nav {
    width: auto;
    flex-direction: row;
    div {
      padding: 5px;
      height: 20px;
      margin: 10px;
      line-height: 16px;
      font-weight: bold;
      font-size: 14px;
      text-align: center;
      cursor: pointer;
      text-transform: uppercase;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    z-index: -1;
    background-color: $absolution-white;
  }
}

.contentWrapper {
  // .fade-enter {
  //   opacity: 0.01;
  // }

  // .fade-enter.fade-enter-active {
  //   opacity: 1;
  //   transition: opacity 1000ms ease-in;
  // }

  // .fade-exit {
  //   opacity: 1;
  // }

  // .fade-exit.fade-exit-active {
  //   opacity: 0.01;
  //   transition: opacity 1000ms ease-out;
  // }
  .fade-enter {
    opacity: 1;
    transform: translateX(-100%);
  }
  .fade-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 0ms, transform 500ms;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0.01;
    transition: opacity 100ms ease-out;
  }
  // .fade-enter-active,
  // .fade-exit-active {
  //   transition: opacity 0ms, transform 500ms;
  // }
}

.content {
  min-height: calc(100vh - 284px);
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 0;
  @media screen and (min-width: $screen-medium) {
    min-height: calc(100vh - 372px);
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    z-index: -1;
    background-color: $absolution-white;
  }
}
