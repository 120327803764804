//Fonts
$ab_header_font: Cinzel;
$ab_content_font: Calibri;

//Colours
$absolution-dark-black: #333333;
$absolution-black: #666666;
$absolution-alternate-black: #495057;
$absolution-white: #f2f7f2;
$background: #f2f7f2;
$sin-new: #3f84e5;
$sin-new-alternate: #4db1c9;
$sin-gift: #b20d30;
$sin-gift-alternate: #a6e37d;
$sin-view: #3f784c;
$sin-guarantee: #ed7d31;
