@import "./variables.scss";
@import "./responsive";

body {
  font-family: $ab-content-font !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-size: 1.6rem !important;
  line-height: 2.2rem !important;
  color: $absolution-black;
  background-color: $background !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $ab_header_font !important;
  font-weight: 500;
}

h1 {
  text-transform: uppercase;
  max-width: calc(100% - 70px);
  margin-bottom: 20px !important;
  font-weight: 600;
}

h3 {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 600;
}

a {
  color: $sin-new;
  position: relative;
}

label {
  font-size: 1.8rem;
  text-transform: uppercase;
}

p {
  font-size: 1.8rem;
  line-height: 2rem;
  &.introText {
    font-weight: bold;
    padding: 0 0 20px;
  }
}

.request__result {
  color: $absolution-black;
}

.form-group {
  margin-bottom: 2rem !important;
}

.btn-primary {
  background-color: $sin-new !important;
  font-size: 1.8rem !important;
  border-color: $sin-new !important;
  padding: 10px 20px !important;
}

.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  margin-bottom: 2rem;
}
